import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Components
import QuickLink from 'components/quick-link/quick-link.component';

// Hooks
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';

// Styles
import './quick-links.style.scss';

const QuickLinks = () => {
    const { t } = useTranslation();
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();

    const handleTransferPrescriptionClick = React.useCallback(() => {
        resetForm();
        setPrescriptionFlowType({ flowType: 'Transfer' });
        navigate('/secure/prescription');
    }, [resetForm, setPrescriptionFlowType]);

    const handleViewMedicineCabinetClick = React.useCallback(() => {
        navigate('/secure/medicine-cabinet');
    }, []);

    return (
        <div className="quick-links">
            <QuickLink
                alignCenter
                hideChevron
                type="button"
                icon="plane-blue"
                iconVariant="info"
                title={t('pages.prescriptionConfirmation.cta.transferPrescription.label')}
                dataGALocation="Transfer Rx"
                onClick={handleTransferPrescriptionClick}
            />
            <QuickLink
                alignCenter
                hideChevron
                type="button"
                icon="rx-fullsize-blue"
                iconVariant="info"
                title={t('pages.prescriptionConfirmation.cta.medicineCabinet.label')}
                dataGALocation="Medicine Cabinet"
                onClick={handleViewMedicineCabinetClick}
            />
        </div>
    );
};

export default QuickLinks;
